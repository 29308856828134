import http from '@/services'
import i18n from '@/i18n.js'

export default {
  async resendVeriMail (creds) {
    const credsWithLang = {
      ...creds,
      lang: i18n.global.locale
    }
    try {
      const response = await http.post('registration.cfc?method=resendVeriMail', credsWithLang)
      return {
        hasError: false,
        logo: response.data.logo,
        msg: response.data.msg,
        resendDate: response.data.resendDate
      }
    } catch (error) {
      return {
        hasError: true,
        logo: error?.response?.data?.logo,
        msg: error?.response?.data?.msg || 'serverError.unknownServerError',
        resendDate: error?.response?.data?.resendDate || 0
      }
    }
  },
  async resendResetMail (creds) {
    const credsWithLang = {
      ...creds,
      lang: i18n.global.locale
    }
    try {
      const response = await http.post('reset.cfc?method=resendResetMail', credsWithLang)
      return {
        hasError: false,
        logo: response.data.logo,
        msg: response.data.msg,
        resendDate: response.data.resendDate
      }
    } catch (error) {
      return {
        hasError: true,
        logo: error?.response?.data?.logo,
        msg: error?.response?.data?.msg || 'serverError.unknownServerError',
        resendDate: error?.response?.data?.resendDate || 0
      }
    }
  }
}
