<template>
  <img
    v-if="logo"
    class="logo"
    :alt="$t('ui.logo')"
    :src="require(`@/assets/${logo}`)"
  >
  <h1
    v-if="msg"
    class="title"
  >
    {{ title }}
  </h1>
  <BaseInfobox
    v-if="msg"
    :type="infoboxType"
    remove-margins
    v-html="msg"
  />

  <BaseButton
    v-if="resendDate"
    type="button"
    :disabled="isDisabled"
    @click="resendMail"
  >
    {{ $t('ui.sendAgain') }}
    <span v-if="remainingTime > 0">
      ({{ remainingTime }})
    </span>
  </BaseButton>
</template>

<script>
import BaseInfobox from '@/components/BaseInfobox.vue'
import BaseButton from '@/components/BaseButton.vue'
import ResendService from '@/services/ResendService.js'
import { setLocale } from '@/util/i18n.js'

export default {
  name: 'ResendPage',
  components: {
    BaseInfobox,
    BaseButton
  },
  data () {
    return {
      creds: {
        email: this.$route.params.email,
        domain: window.location.host
      },
      logo: '',
      msg: '',
      infoboxType: '',
      resendDate: undefined,
      remainingTime: undefined,
      intervalId: undefined
    }
  },
  computed: {
    title () {
      return this.infoboxType === 'success' ? this.$t('ui.operationSuccessful') : this.$t('clientError.operationFailed')
    },
    /* Boolean-Attribut muss "null" sein, damit es entfernt wird */
    isDisabled () {
      return this.remainingTime > 0 ? '' : null
    }
  },
  async created () {
    /* Man kommt zwar von einer anderen Route,
       aber falls die Seite neu geladen wird. */
    setLocale(this.$route.query.lang)

    this.resendMail()
  },
  beforeUnmount () {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
  methods: {
    async resendMail () {
      let response = null

      switch (this.$route.params.type) {
        case 'verification-mail':
          response = await this.resendVeriMail(this.creds)
          break
        case 'reset-mail':
          response = await this.resendResetMail(this.creds)
          break
        default:
          this.msg = this.$t('clientError.linkInvalid')
          this.infoboxType = 'error'
      }

      if (response) {
        this.logo = response.logo
        this.msg = this.$t(response.msg)
        this.infoboxType = response.hasError ? 'error' : 'success'
        this.resendDate = response.resendDate
        this.intervalId = setInterval(this.calculateRemainingTime, 1000)
      }
    },
    async resendVeriMail (creds) {
      creds.regId = this.$route.params.id
      return ResendService.resendVeriMail(creds)
    },
    async resendResetMail (creds) {
      creds.appId = this.$route.params.id
      return ResendService.resendResetMail(creds)
    },
    calculateRemainingTime () {
      this.remainingTime = this.resendDate - Math.floor(Date.now() / 1000)
    }
  }
}
</script>
